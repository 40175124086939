import { MountIndexSplide } from './utils/splide-stuff'

var indexSplide = null

window.document.addEventListener('DOMContentLoaded', () => {
  indexSplide = MountIndexSplide()
})

window.document.topCarouselGoPrev = () => {
  indexSplide.go('<')
}
window.document.topCarouselGoNext = () => {
  indexSplide.go('>')
}