
import Splide from '@splidejs/splide'

function MountIndexSplide() {

	return new Splide( '#top-offer-carousel' , {
		type   : 'loop',
		perPage: 1,
		perMove: 1,
		autoplay: true,
		interval: 4400,
		rewind: true,
		speed: 1000,
		pagination: false,
		classes: {
			arrows: 'splide__arrows',
			arrow : 'splide__arrow top-offer-carousel__arrow',
			prev  : 'splide__arrow--prev top-offer-carousel__arrow-left',
			next  : 'splide__arrow--next top-offer-carousel__arrow-right',
		},
	}).mount()
	
}

function MountReviewsSplide() {

	new Splide( '.store-reviews' , {
		type   : 'loop',
		perPage: 3,
		breakpoints: {
			1100: {
				perPage: 2,
			},
			900: {
				perPage: 1,
			},
		},
		perMove: 1,
		autoplay: true,
		interval: 4400,
		rewind: true,
		speed: 1000,
		arrows: false,
		pagination: false,
		padding: { left: 25, right: 25 },
	}).mount()

}

function MountMobileCertificatesSplide() {

	new Splide( '#certificates-mobile' , {
		type   : 'loop',
		perPage: 1,
		perMove: 1,
		autoplay: true,
		interval: 4400,
		rewind: true,
		speed: 1000,
		arrows: false,
		pagination: false,
		padding: { left: 75, right: 75 },
	}).mount()

}


export {
	MountIndexSplide,
	MountReviewsSplide,
	MountMobileCertificatesSplide,
}